import { useStaticQuery, Link, graphql } from "gatsby"
import React from "react"
import SocialMediaLinks from "@one-builder/gatsby-theme-ob-master/src/components/social-links"
import Image from "@one-builder/gatsby-theme-ob-master/src/components/common/image"
// import NavigationItem from "@one-builder/gatsby-theme-ob-master/src/components/layout/navigation/navigation_item"
import styles from "@one-builder/gatsby-theme-ob-master/src/styles/global.module.scss"

const Footer = ({ metadata }) => {
  // Get footer services and navigation
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          frontmatter: { is_active: { eq: true } }
          fileAbsolutePath: { regex: "/(/service_categories/)/" }
        }
        sort: { fields: frontmatter___name }
        limit: 7
      ) {
        edges {
          node {
            id
            frontmatter {
              name
            }
            fields {
              slug
            }
          }
        }
      }
      navigationYaml {
        navigation {
          nav_item {
            page_id
          }
        }
      }
      all_pages: allMarkdownRemark {
        edges {
          node {
            frontmatter {
              page_id
              title
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <footer>
        <div className={styles.container}>
          <div className={styles.footer_sub}>
            <div className={styles.footer_logo}>
              <Link to="/">
                <div style={{ width: "100%" }}>
                  <Image filename="logo.png" alt={metadata.CompanyPhone} />
                </div>
              </Link>
            </div>
            <div className={styles.contactbox}>
              <span>
                <a
                  href={`tel:${metadata.CompanyPhone}`}
                  title={metadata.CompanyPhone}
                >
                  {metadata.CompanyPhone}
                </a>
              </span>
              <span>
                <a
                  href={`mailto:${metadata.CompanyEmail}`}
                  title={metadata.CompanyEmail}
                >
                  {metadata.CompanyEmail}
                </a>
              </span>
              <span>
                <div title={metadata.CompanyAddress.city}>
                  {metadata.CompanyAddress.street}{" "}
                  {metadata.CompanyAddress.city}
                  {", "}
                  {metadata.CompanyAddress.state}
                </div>
              </span>
              <span>
                <div>Monday-Friday 8:00am-4:00pm</div>
              </span>
            </div>
          </div>
          <div className={styles.footer_sub}>
          <div className={styles.buttonContain}>
                    <Link
                      className={styles.btn}
                      to={`/contact/`}
                      title="Contact Us"
                    >
                      Contact Us
                    </Link>
                  </div>
          <SocialMediaLinks />
          {/* <div className={styles.bottom_nav}>
            {data.navigationYaml.navigation
              ? data.navigationYaml.navigation.map((item, i) => {
                  if (data.all_pages) {
                    let page_name = data.all_pages.edges.find(
                      p => p.node.frontmatter.page_id === item.nav_item.page_id
                    )
                    if (page_name) {
                      item.nav_item.page = page_name.node.frontmatter.title
                    }
                  }
                  return (
                    <span key={i}>
                      <NavigationItem nav_item={item.nav_item} />
                    </span>
                  )
                })
              : null}
            <Link to="/faq/">FAQ</Link>
            </div> */}
          </div>
        </div>
      </footer>
      <div className={styles.footerBar}>
        <div className={styles.container}>
          <div>
            © {new Date().getFullYear()}
            {` `}
            {metadata.CompanyName}
          </div>
          <div>
            <Link to="/privacy-policy/">Privacy Policy</Link>
            <Link to="/terms-and-conditions/">Terms of Use</Link>
            <a href="/sitemap.xml">Sitemap</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
