import React, { Fragment } from "react"
import { Link } from "gatsby"
import styles from "../../styles/global.module.scss"

export default function BreadCrumbs(props) {
  const { breadcrumbs } = props
  let path = ""
  return (
    <div id={styles.breadcrumbs}>
      <div className={styles.container}>
        <ul className={styles.flexWrapper}>
          <li>
            <Link to={`/`}>
              <span>Home</span>
            </Link>
          </li>
          {breadcrumbs.map((breadcrumb) => {
            
            console.log(breadcrumb)
            // build url path
            console.log(!path.includes(breadcrumb))
              path += `${breadcrumb.toLowerCase()}/`
              // : path = path
            return (
              <Fragment key={breadcrumb}>
                <li className={styles.bcSlash}>
                  <span>&raquo;</span>
                </li>
                <li>
                    <Link to={path}>
                      <span>{breadcrumb.replace(/-/g, " ")}</span>
                    </Link>
                  </li>
              </Fragment>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
