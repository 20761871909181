import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Headroom from "react-headroom"
import SocialMediaLinks from "@one-builder/gatsby-theme-ob-master/src/components/social-links"
import Logo from "@one-builder/gatsby-theme-ob-master/src/components/logo"
import Navigation from "@one-builder/gatsby-theme-ob-master/src/components/layout/navigation/navigation"
import AlertBanner from "@one-builder/gatsby-theme-ob-master/src/components/layout/alert-banner"

import headerStyles from "../../styles/global.module.scss"

const Header = ({ metadata }) => {
  return (
    <div>
      <AlertBanner metadata={metadata} />
      <div className={headerStyles.topBar}>
        <div className={headerStyles.container}>
          <div className={headerStyles.headLeft}>
 
          <div className={headerStyles.sub}>
          <SocialMediaLinks />
            <span className={headerStyles.phone}>
              <a
                href={`tel:${metadata.CompanyPhone}`}
                title={metadata.CompanyPhone}
              >
                {metadata.CompanyPhone}
              </a>
            </span>
            |
            <span className={headerStyles.address}>
              {metadata.CompanyAddress.city}, {metadata.CompanyAddress.state}
            </span>
          </div>
          <span className={headerStyles.license}>Fully Insured and Licensed in PA and Scranton PA HIC #PA052074</span>
        </div>

          {/* <div className={headerStyles.sub}>
          Fully Insured and Licensed in PA and Scranton pa hic #PA052074
          </div> */}
          <Navigation />
        </div>
      </div>
      <header className={headerStyles.header}>
        <div
          className={`${headerStyles.container} ${headerStyles.flexWrapper}`}
        >
          <div className={headerStyles.logo}>
            <Link
              aria-label={metadata.CompanyName}
              alt={metadata.CompanyName}
              to="/"
            >
              <Logo />
            </Link>
          </div>
        </div>
      </header>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
