import React from "react"
import { Link } from "gatsby"
import styles from "@one-builder/gatsby-theme-ob-master/src/styles/global.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import createSlug from "@one-builder/gatsby-theme-ob-master/src/helpers/createSlug"
import isExternalLink from "../../../helpers/isExternalLink"

export default function NavigationItem({ nav_item }) {
  // Initialize current nav item
  let this_nav_item, tab_name, caret

  // If label, set nav_item name to label, else set to page name
  nav_item.label ? (tab_name = nav_item.label) : (tab_name = nav_item.page)

  // Handle sub menu click in mobile menu
  const handleSubMenu = e => {
    e.preventDefault()
    let current_item = document.getElementById(tab_name)
    current_item.nextElementSibling.classList.toggle(styles.subNavActive)
  }

  // If sub nav exists, show caret
  if (nav_item.sub_items && nav_item.sub_items.length > 0) {
    caret = (
      <button
        className={styles.caret}
        onClick={e => handleSubMenu(e)}
        aria-label="Sub Navigation Dropdown"
      >
        <FontAwesomeIcon icon={faCaretDown} />
      </button>
    )
  }

  // Check if internal or external URL & build link
  let is_external_link = isExternalLink(nav_item)
  if (is_external_link) {
    this_nav_item = (
      <>
        <a
          id={tab_name}
          href={nav_item.url}
          title={nav_item.tab_name}
          target="_blank"
          rel="noopener noreferrer"
          data-hover={tab_name}
        >
          <span>
            {tab_name} {caret}
          </span>
        </a>
      </>
    )
  } else {
    // Build slug
    let slug = ""
    if (nav_item.page) {
      slug = createSlug(nav_item.page)
    }
    if (tab_name === "Home") {
      slug = ""
    }
    if (tab_name === "Gallery") {
        slug = "gallery"
      }
    this_nav_item = (
      <>
        <Link to={`/${slug}/`} data-hover={tab_name} id={tab_name}>
          <span>
            {tab_name} {caret}
          </span>
        </Link>
      </>
    )
  }

  return this_nav_item
}
